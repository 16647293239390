<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page2">
            <div class="box_con">
              <div class="box_list_wrap">
                <div class="box_shadow m_8">
                  <div class="box box_one">
                    <div class="box_list_wrap">
                      <div class="box_list">
                        <div class="list_ttl">
                          <div class="left">
                            <div class="m_4">
                              <span class="build_name">{{ buildingName }}</span>
                            </div>
                            <p class="white_wrap ft_m_16">
                              {{ form.title }}
                            </p>
                            <div class="price_box">
                              <span class="price_txt">{{
                                $dayjs(form.regDate).format(
                                  'YYYY.MM.DD HH:mm:ss'
                                )
                              }}</span>
                            </div>
                          </div>
                          <div class="middle_box flex1none">
                            <div class="middle">
                              <p
                                class="price r_12"
                                :class="{
                                  f_g: form.isReply === 0,
                                  deposit: form.isReply === 1
                                }"
                              >
                                {{
                                  form.isReply === 0 ? '답변대기' : '답변완료'
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="line_grey none"></div>
                    </div>
                    <div class="text_box editor">
                      <p>{{ form.contents }}</p>
                      <div class="img_box">
                        <img
                          v-for="(item, index) in filePaths"
                          :key="index"
                          :src="
                            $ConstCode.getImagePath(item.imagePath, 'origin/')
                          "
                          alt="img"
                          @click="fnPopUp(item.imagePath)"
                        />
                      </div>
                      <div class="line_grey"></div>
                    </div>
                    <div class="answer" v-if="form.isReply === 0">
                      <div class="answer_none">
                        <p>관리자가 민원을 확인하고 있습니다.</p>
                        <p>조금만 더 기다려주세요.</p>
                      </div>
                    </div>
                    <div class="answer" v-else>
                      <div class="answer_complete">
                        <span class="price_txt">{{
                          $dayjs(form.regDate).format('YYYY.MM.DD HH:mm:ss')
                        }}</span>
                        <p class="answer_box">
                          {{ form.replyContents }}
                        </p>
                      </div>
                      <div
                        class="text_box editor"
                        v-if="!$Util.isEmpty(form.replyImagePath)"
                      >
                        <p>{{ form.contents }}</p>
                        <div class="img_box">
                          <img
                            :src="
                              $ConstCode.getImagePath(
                                form.replyImagePath,
                                'origin/'
                              )
                            "
                            alt="img"
                            @click="fnPopUp(form.replyImagePath)"
                          />
                        </div>
                        <div class="line_grey"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page_btn" v-if="form.isReply === 0">
          <div class="btn_wrap">
            <div class="inline">
              <button class="btn left" @click="fnDelete">삭제하기</button>
              <button class="btn on" @click="fnEdit">수정하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'menu-complaint-view',
  description: '민원접수 상세페이지',
  setup() {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const query = route.query

    const buildingName = store.getters['user/getData'].buildingName

    const state = reactive({
      form: {
        title: '',
        contents: '',
        regDate: '',
        isReply: '',
        replyContents: '',
        replyDate: '',
        replyImagePath: ''
      },
      filePaths: []
    })

    const fetchData = async () => {
      const res = await proxy.$ComplaintSvc.fetchComplaint(query)
      console.log(res)
      if (res.code === 1) {
        state.form = res.entity.res
        state.filePaths = res.entity.filePaths
      }
    }

    const fnEdit = () => {
      router.push({ path: '/menu/complaint/write', query: query })
    }

    const fnDelete = async () => {
      if (!confirm('삭제하시겠습니까?')) return false
      const res = await proxy.$ComplaintSvc.deleteComplaint(query)
      if (res.code === 1) {
        alert('삭제되었습니다.')
        await router.go(-1)
      }
    }

    const fnPopUp = async path => {
      const payload = {}
      payload.component = proxy.$modalAlertNames.IMAGE
      payload.layout = {
        imagePath: path
      }
      payload.callBack = proxy => {
        proxy.$emit('close')
      }
      await store.dispatch('layout/pushModalAlertComponent', payload)
    }

    fetchData()
    return {
      ...toRefs(state),
      fetchData,
      fnEdit,
      fnDelete,
      buildingName,
      fnPopUp
    }
  }
}
</script>

<style scoped></style>
